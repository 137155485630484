import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { ENV, PROD } from '../constants';

import { Layout, PostCard, Pagination } from '../components/common'
import { MetaData } from '../components/common/meta'
import Home from '../components/Home';
import LogRocket from 'logrocket';

if (ENV === PROD) {
    LogRocket.init('e6n2nc/symposeum-production');
}

/**
* Main index page (home page)
*
* Loads all posts from Ghost and uses pagination to navigate through them.
* The number of posts that should appear per page can be setup
* in /utils/siteConfig.js under `postsPerPage`.
*
*/

const Container = ({children}) => (
    <div class="w-screen-lg mx-auto px-4 px-32 sm:px-64 lg:px-64">
  {/* <div class="max-w-3xl mx-auto"> */}
  {children}
  {/* </div> */}
</div>
)

const HomeContainer = ({children}) => (
    <div class="w-screen-lg mx-auto px-4 xs:pl-32 sm:pl-64 lg:pl-64" style={{padding: '2vw 0vw', paddingBottom: 0}}>
    {/* <div class="max-w-3xl mx-auto"> */}
    {children}
    {/* </div> */}
  </div>
)

const Index = ({ data, location, pageContext }) => {
    const home = data.allGhostPage.edges.find(Boolean);

    return (
        <>
            <MetaData location={location} />
            <Layout isHome={true}>
                <HomeContainer>
                    <Home home={home} data={data}/>
                    {/* <section className="post-feed">
                        {posts.map(({ node }) => (
                            // The tag below includes the markup for each post - components/common/PostCard.js
                            <PostCard key={node.id} post={node} />
                        ))}
                    </section> */}
                    {/* <Pagination pageContext={pageContext} /> */}
                    </HomeContainer>            
                    </Layout>
        </>
    )
}

Index.propTypes = {
    data: PropTypes.shape({
        allGhostPost: PropTypes.object.isRequired,
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    pageContext: PropTypes.object,
}

export default Index

// This page query loads all posts sorted descending by published date
// The `limit` and `skip` values are used for pagination
export const pageQuery = graphql`
    query MyQuery {
        allGhostPage(filter: {slug: {eq: "home"}}) {
            edges {
                node {
                html,
                feature_image
                }
            }
        }
        allGhostPost {
            edges {
                node {
                    tags {
                        name
                        slug
                    }
                    authors {
                        name
                        slug
                    }
                    title
                    slug
                    feature_image
                    excerpt
                }
            }
        }
        allGhostTag {
            nodes {
                slug
                name
                visibility
                feature_image
                description
                meta_title
                meta_description
                og_image
            }
        }
    }  
`